import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/_services';

declare var $:any;

@Component({
  selector: 'app-set-test',
  templateUrl: './set-test.component.html',
  styleUrls: ['./set-test.component.less']
})
export class SetTestComponent implements OnInit {

  testFind: string;
  result: any;
  loading: boolean = false;
  isEditable: boolean = false;
  currTest: any;
  _statuses: String[] = ['Waiting for test', 'In progress', 'Finished'];
  _results: String[] = ['Not finished','Negative','Positive','Invalid'];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    document.body.classList.add('bg-list');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-list');
  }

  getLabTest() {
    this.isEditable = false;
    if(this.testFind && this.testFind.length === 0) {
      return false;
    }
    this.loading = true;
    this.userService.getLaborTest(this.testFind).subscribe({
      next: (data) => {
        this.loading = false;
        this.result = data.data;
      },
      error: (data) => {
          this.loading = false;
          console.log(data);
      }
    });
  }

  setEditor() {
    if (this.isEditable) {
      this.currTest = null;
      return this.isEditable = false;
    }
    this.currTest = JSON.parse(JSON.stringify(this.result));
    return this.isEditable = true;
  }

  openModal(){
    if(JSON.stringify(this.currTest) === JSON.stringify(this.result)) {
      return;
    }
    $('#myModal').modal('show');
  }

  hideModal(){
    $('#myModal').modal('hide');
  }

  submitChange() {
    this.userService.setLaborTest(this.currTest).subscribe({
      next: (data) => {
          this.hideModal();
          this.setEditor();
          this.getLabTest();
      },
      error: (data) => {
          this.hideModal();
          this.setEditor();
          this.getLabTest();
      }
    });
  }

}
