<p class="h4 mt-5">
  Find test by ID
</p>
<div class="card mt-3 mb-5">
    <div class="card-body">
        <input type="text" class="form-control" id="example1" name="testfind" [(ngModel)]="testFind">
        <button [disabled]="loading" class="btn lblue-btn bigger my-3" (click)="getLabTest()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Get test details
        </button>
    </div>
</div>
<p class="h4 mt-5" *ngIf="!loading && result">
  Set test status and result
</p>
<div *ngIf="loading" class="text-center mt-3">
    <div class="spinner-border text-lblue" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="!loading && result && result.testId" class="table-wrapper">
  <table  class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Test type</th>
          <th scope="col">Test ID</th>
          <th scope="col">Status</th>
          <th scope="col">Result</th>
          <th scope="col">Date</th>
          <th scope="col">Edit</th>
        </tr>
      </thead>
      <tbody>
        <tr>
            <td>{{result.testType}}</td>
            <td>{{result.testId}}</td>
            <td>
                <select class="form-select form-control" [(ngModel)]="currTest.status" *ngIf="isEditable">
                    <option value="0">Waiting for test</option>
                    <option value="1">In progress</option>
                    <option value="2">Finished</option>
                </select>
                <span *ngIf="!isEditable">
                    {{_statuses[result.status]}}
                </span>
            </td>
            <td>
                <select class="form-select form-control" [(ngModel)]="currTest.result" *ngIf="isEditable">
                    <option value="0">Not finished</option>
                    <option value="1">Negative</option>
                    <option value="2">Positive</option>
                    <option value="3">Invalid</option>
                </select>
                <span *ngIf="!isEditable">
                    {{_results[result.result]}}
                </span>
            </td>
          <td>{{result.dateTime}}</td>
          <td>
            <button class="btn lblue-btn" (click)="setEditor()">{{isEditable ? 'Close':'Edit'}}</button>
            <button class="btn lblue-btn mt-1" data-bs-toggle="modal" data-bs-target="#myModal" (click)="openModal()" *ngIf="isEditable">Save</button>
        </td>
        </tr>
      </tbody>
  </table>
</div>
<div *ngIf="!loading && result && result.length === 0" class="table-wrapper">
    <table  class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Empty data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Test not found</td>
          </tr>
        </tbody>
    </table>
  </div>
  <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" *ngIf="isEditable">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title text-center" id="exampleModalLabel">Are you want to save changes?</h5>
          <div class="text-center my-4">
            <p *ngIf="result.status != currTest.status">Status: {{_statuses[result.status]}} -> {{_statuses[currTest.status]}}</p>
            <p *ngIf="result.result != currTest.result">Result: {{_results[result.result]}} -> {{_results[currTest.result]}}</p>
          </div>
          <div class="text-center">
            <button type="button" class="btn lblue-btn bigger d-inline-block mr-3" (click)="submitChange()">Yes</button>
            <button type="button" class="btn lblue-btn bigger d-inline-block" data-bs-dismiss="modal" (click)="hideModal()">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>