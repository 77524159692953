import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User, PositiveResults } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getPositiveResults(page?: string, user?: string, loc?: string, start?: string, end?: string) {
        let params = new HttpParams().set("page",page).set("user",user).set("loc",loc).set("start",start).set("end",end);
        return this.http.get<any>(`${environment.apiUrl}get-positive-results${environment.extension}`, {params: params}).pipe(map(data => {
            return data;
        }));
    }

    getAllResults() {
        return this.http.get<any>(`${environment.apiUrl}get-all-results${environment.extension}`).pipe(map(data => {
            return data.data;
        }));
    }

    getWebUsers() {
        return this.http.get<any>(`${environment.apiUrl}get-web-users${environment.extension}`).pipe(map(data => {
            return data;
        }));
    }

    getLaborTest(id?: string) {
        let params = new HttpParams().set("id",id);
        return this.http.get<any>(`${environment.apiUrl}get-labor-test${environment.extension}`, {params: params}).pipe(map(data => {
            return data;
        }));
    }

    setLaborTest(details: any) {
        return this.http.post<any>(`${environment.apiUrl}set-labor-test${environment.extension}`, {data: details}).pipe(map(data => {
            return data;
        }));
    }

    setUserDetails(details: any) {
        return this.http.post<any>(`${environment.apiUrl}set-web-user${environment.extension}`, {data: details}).pipe(map(data => {
            return data;
        }));
    }

    getStatistics() {
        return this.http.get<any>(`${environment.apiUrl}get-statistics${environment.extension}`).pipe(map(data => {
            return data.data;
        }));
    }

    generateBarcode() {
        return this.http.get<any>(`${environment.apiUrl}generate-barcode${environment.extension}`).pipe(map(data => {
            return data.data;
        }));
    }

    addUser(details: any) {
        return this.http.post<any>(`${environment.apiUrl}add-web-user${environment.extension}`, {data: details}).pipe(map(data => {
            return data;
        }));
    }
}