import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MultiDataSet, Color, Label } from 'ng2-charts';

import { User } from '@app/_models';
import { UserService } from '@app/_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    loading = false;
    users: User[];
    statistics = {
      'all': 1745526, 
      'waiting': 92542, 
      'inProgress': 305704, 
      'finished': 1347280, 
      'resNeg': 921755, 
      'resPos': 412315, 
      'resInv': 13210,
      'patientsAll': 1432011,
      'patientsMonth': 190321,
      'patientsWeek': 45264,
      'patientsDay': 3712
    };

    _months: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // line chart data
    lineChartData: ChartDataSets[] = [
      { data: [288132, 657100, 952120, 1010931, 1231622, 1380021], label: 'Patients', borderWidth: 1 },
    ];

    lineChartLabels: Label[] = ['April', 'June', 'July', 'August', 'September', 'October'];

    lineChartOptions = {
      responsive: true,
      aspectRatio: 1.5,
      scales: {
        yAxes: [{
            ticks: {
                fontColor: '#cacaca',
                beginAtZero: true
            }
        }],
        xAxes: [{
          ticks: {
            fontColor: '#cacaca',
          }
        }]
      },
      legend: {
        labels: {
            fontColor: '#cacaca'
        }
      }
    };

    lineChartColors: Color[] = [
      {
        borderColor: '#9BDCF0',
        backgroundColor: 'rgba(155, 220, 240,0.5)'
      },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    // doughnut chart data
    doughnutChartLabels: Label[] = ['Positive', 'Negative', 'Invalid'];
    doughnutChartData: MultiDataSet = [
      [this.statistics.resPos, this.statistics.resNeg, this.statistics.resInv]
    ];
    doughnutChartData24: MultiDataSet = [
      [10310, 35219, 529]
    ];
    doughnutChartType: ChartType = 'doughnut';

    doughnutChartOptions: ChartOptions = {
      responsive: true,
      title: {
        fontColor: '#cacaca'
      },
      legend: {
        labels: {
            fontColor: '#cacaca'
        }
      }
    }

    donutColors=[
      {
        backgroundColor: [
          'rgba(155, 220, 238, 1)',
          'rgba(245, 253, 255, 1)',
          'rgba(157, 150, 140, 1)',
      ],
      borderColor: 'rgba(155, 220, 238, 1)',
      borderWidth: 1
      }
    ];

    // bar chart data
    barChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        yAxes: [{
            ticks: {
                fontColor: '#cacaca',
                beginAtZero: true
            }
        }],
        xAxes: [{
          ticks: {
            fontColor: '#cacaca',
          }
        }]
      },
      legend: {
        labels: {
            fontColor: '#cacaca'
        }
      }
    };
    barChartLabels: Label[] = ['April', 'June', 'July', 'August', 'September', 'October'];
    barChartType: ChartType = 'bar';
    barChartLegend = true;
    barChartPlugins = [];

    barChartData: ChartDataSets[] = [
      { data: [4520, 3117, 2643, 4021, 6251, 7123], label: 'Positive', backgroundColor: 'rgba(155, 220, 238, 1)', hoverBackgroundColor: 'rgba(135, 200, 218, 1)' },
      { data: [27602, 39275, 35123, 40230, 52812, 74544], label: 'Negative', backgroundColor: 'rgba(245, 253, 255, 1)', hoverBackgroundColor: 'rgba(225, 233, 235, 1)' , borderWidth: 1, borderColor: 'rgba(155, 220, 238, 1)' },
      { data: [125, 1241, 782, 423, 1532, 2644], label: 'Invalid', backgroundColor: 'rgba(157, 150, 140, 1)' , hoverBackgroundColor: 'rgba(137, 130, 120, 1)'}
    ];

    constructor(private userService: UserService) { 
      this.getStatistics();
    }

    ngOnInit(): void {
      document.body.classList.add('bg-list');
      this.monthSetter();
    }
  
    ngOnDestroy(): void {
      document.body.classList.remove('bg-list');
    }

    getStatistics() {
      this.userService.getStatistics().subscribe({
        next: (data) => {
            if(data != null) {
              this.statistics.all = this.statistics.all + data.all;
              this.statistics.waiting = this.statistics.waiting + data.waiting;
              this.statistics.inProgress = this.statistics.inProgress + data.inProgress;
              this.statistics.finished = this.statistics.finished + data.finished;
              this.statistics.resNeg = this.statistics.resNeg + data.resNeg;
              this.statistics.resPos = this.statistics.resPos + data.resPos;
              this.statistics.resInv = this.statistics.resInv + data.resInv;
              this.statistics.patientsAll = this.statistics.patientsAll + data.patientsAll;
              this.statistics.patientsMonth = this.statistics.patientsMonth + data.patientsMonth;
              this.statistics.patientsWeek = this.statistics.patientsWeek + data.patientsWeek;
              this.statistics.patientsDay = this.statistics.patientsDay + data.patientsDay;
              this.doughnutChartData = [
                [this.statistics.resPos, this.statistics.resNeg, this.statistics.resInv]
              ];
            }
        },
        error: (data) => {
            console.log(data);
        }
    });
    }

    monthSetter() {
      let d = new Date();
      let n = d.getMonth();
      for(let i = 6; i > 0; i--) {
        this.lineChartLabels[i-1] = this._months[n];
        this.barChartLabels[i-1] = this._months[n];
        if(n === 0) {
          n = 12;
        }
        n--;
      }
    }
}
