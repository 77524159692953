<div class="row my-4">
  <div class="col-12">
    <p class="h4">
      Patients registration statistics
    <p>
    <div class="card white-card mt-4">
      <div class="card-body">
        <div class="row inner-cards">
          <div class="col-12 col-md-6">
            <p class="h5">
              Last 6 months
            <p>
            <div class="card chart-card mt-4">
              <div class="card-body">
                <canvas baseChart
                  [datasets]="lineChartData"
                  [labels]="lineChartLabels"
                  [options]="lineChartOptions"
                  [colors]="lineChartColors"
                  [legend]="lineChartLegend"
                  [chartType]="lineChartType"
                  [plugins]="lineChartPlugins">
                </canvas>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card detail-card mt-4">
                  <div class="card-body">
                    <p>
                      All
                    </p>
                    <span>
                      {{statistics.patientsAll | number}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card detail-card mt-4">
                  <div class="card-body">
                    <p>
                      Last 30 days
                    </p>
                    <span>
                      {{statistics.patientsMonth | number}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card detail-card mt-4">
                  <div class="card-body">
                    <p>
                      Last 7 days
                    </p>
                    <span>
                      {{statistics.patientsWeek | number}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card detail-card mt-4">
                  <div class="card-body">
                    <p>
                      Last 24 hours
                    </p>
                    <span>
                      {{statistics.patientsDay | number}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row my-4">
  <div class="col-12">
    <p class="h4">
      Tests statistics
    <p>
    <div class="card white-card mt-4">
      <div class="card-body">
        <div class="row inner-cards">
          <div class="col-6 col-md-3">
            <div class="card detail-card mt-4">
              <div class="card-body">
                <p>
                  All registered tests
                </p>
                <span>
                  {{statistics.all | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="card detail-card mt-4">
              <div class="card-body">
                <p>
                  Waiting for test
                </p>
                <span>
                  {{statistics.waiting | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="card detail-card mt-4">
              <div class="card-body">
                <p>
                  In progress
                </p>
                <span>
                  {{statistics.inProgress | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="card detail-card mt-4">
              <div class="card-body">
                <p>
                  Finished
                </p>
                <span>
                  {{statistics.finished | number}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-5">
            <p class="h5">
              All tests results
            </p>
            <div class="card mt-4 chart2-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <canvas baseChart height="200"
                      [data]="doughnutChartData"
                      [labels]="doughnutChartLabels"
                      [chartType]="doughnutChartType"
                      [colors]="donutColors"
                      [options]="doughnutChartOptions">
                    </canvas>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="tests-text my-2">
                      Positive cases
                      <br>
                      <span>
                        {{statistics.resPos | number}}
                      </span>
                    </div>
                    <div class="tests-text my-2">
                      Negative cases
                      <br>
                      <span>
                        {{statistics.resNeg | number}}
                      </span>
                    </div>
                    <div class="tests-text my-2">
                      Invalid cases
                      <br>
                      <span>
                        {{statistics.resInv | number}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-5">
            <p class="h5">
              Last 24 hours tests results
            </p>
            <div class="card mt-4 chart2-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <canvas baseChart height="200"
                      [data]="doughnutChartData24"
                      [labels]="doughnutChartLabels"
                      [chartType]="doughnutChartType"
                      [colors]="donutColors"
                      [options]="doughnutChartOptions">
                    </canvas>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="tests-text my-2">
                      Positive cases
                      <br>
                      <span>
                        10,310
                      </span>
                    </div>
                    <div class="tests-text my-2">
                      Negative cases
                      <br>
                      <span>
                        35,219
                      </span>
                    </div>
                    <div class="tests-text my-2">
                      Invalid cases
                      <br>
                      <span>
                        529
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5">
            <p class="h5">
              Results in last 6 months (<i>monthly</i>)
            </p>
            <div class="card chart-card mt-4">
              <div class="card-body">
                <canvas baseChart
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [legend]="barChartLegend"
                  [chartType]="barChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>