import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { UserService } from '@app/_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $:any;

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.less']
})
export class UserManageComponent implements OnInit {

  usersList: User[] = [];
  loading: Boolean = true;
  currEdit: number;
  currEditUser: User;
  levelFilter: number;
  usernameFilter: string;
  emailFilter: string;
  phoneFilter: string;
  isNewUser: boolean = false;

  nUser: User = new User();

  constructor(private userService: UserService, private formBuilder: FormBuilder,) {
    this.getWebUsers();
  }

  ngOnInit(): void {
    document.body.classList.add('bg-list');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-list');
  }

  getWebUsers() {
    this.loading = true;
    this.userService.getWebUsers().subscribe({
      next: (data) => {
        this.loading = false;
        this.usersList = data.data;
      },
      error: (data) => {
          this.loading = false;
          console.log(data);
      }
  });
  }

  setEditor(num: number) {
    if (this.currEdit != null && this.currEdit === num) {
      this.currEditUser = null;
      return this.currEdit = null;
    }
    this.currEditUser = JSON.parse(JSON.stringify(this.usersList[num]));
    return this.currEdit = num;
  }

  saveState() {
    console.log(this.currEditUser);
    this.currEdit = null;
  }

  openModal(){
    if(JSON.stringify(this.currEditUser) === JSON.stringify(this.usersList[this.currEdit])) {
      return;
    }
    $('#myModal').modal('show');
  }

  hideModal(){
    $('#myModal').modal('hide');
  }

  submitChange() {
    this.userService.setUserDetails(this.currEditUser).subscribe({
      next: (data) => {
          this.hideModal();
          this.setEditor(this.currEdit);
          this.getWebUsers();
      },
      error: (data) => {
          this.hideModal();
          this.setEditor(this.currEdit);
          this.getWebUsers();
      }
    });
  }

  addNewUser() {
    this.userService.addUser(this.nUser).subscribe({
      next: (data) => {
          this.nUser = new User();
          this.isNewUser = false;
          this.getWebUsers();
      },
      error: (data) => {
          //this.getWebUsers();
      }
    });
  }

}
