export class PositiveResults {
    id: number;
    firstName: string;
    lastName: string;
    phoneNum: string;
    gender: number;
    birthDate: string;
    deviceId: string;
    testId: string;
    testType: number;
    location: string;
    testDate: string;
    userId: string;
}