<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
    <div class="container">
      <a class="navbar-brand">DELS-C</a>
      <div class="navbar-nav mr-auto mb-lg-0">
        <a class="nav-item nav-link" routerLink="/positive-results" routerLinkActive="active" *ngIf="currentUser.level <= 3 && currentUser.level >= 0">Positive results</a>
        <a class="nav-item nav-link" routerLink="/all-results" routerLinkActive="active" *ngIf="currentUser.level <= 2 && currentUser.level >= 0">All results</a>
        <a class="nav-item nav-link" routerLink="/statistics" routerLinkActive="active" *ngIf="currentUser.level <= 2 && currentUser.level >= 0">Statistics</a>
        <a class="nav-item nav-link" routerLink="/manage-users" routerLinkActive="active" *ngIf="currentUser.level <= 1 && currentUser.level >= 0">Manage users</a>
        <a class="nav-item nav-link" routerLink="/generate-barcode" routerLinkActive="active" *ngIf="currentUser.level <= 1 && currentUser.level >= 0">Generate barcode</a>
        <a class="nav-item nav-link" routerLink="/set-test" routerLinkActive="active" *ngIf="currentUser.level == -1">Set test</a>
        <a class="nav-item nav-link last-item" (click)="logout()">Logout</a>
      </div>
    </div>
</nav>

<!-- main app container -->
<div class="container">
    <router-outlet></router-outlet>
</div>

<!-- credits -->
<div class="text-center mt-4" *ngIf="currentUser">
    <p>
        <a href="https://implasens.com" target="_top">Implasens Biosensoring GmbH</a>
    </p>
</div>
