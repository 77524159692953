import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/_services';

@Component({
  selector: 'app-generate-barcode',
  templateUrl: './generate-barcode.component.html',
  styleUrls: ['./generate-barcode.component.less']
})
export class GenerateBarcodeComponent implements OnInit {

  genVal: String = null;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  generateBarcode() {
    this.userService.generateBarcode().subscribe({
      next: (data) => {
          this.genVal = data;
      },
      error: (data) => {
          console.log(data);
      }
  });
  }

}
