import { Component, OnInit } from '@angular/core';
import { PositiveResults } from '@app/_models';
import { UserService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

declare var $:any;

@Component({
  selector: 'app-positive-results',
  templateUrl: './positive-results.component.html',
  styleUrls: ['./positive-results.component.less']
})
export class PositiveResultsComponent implements OnInit {

  results: PositiveResults[] = [];
  loading: Boolean = true;
  allPages: number = 1;
  currPage: number = 1;
  pageParam: number;
  userParam: string;
  locParam: string;
  startParam: string;
  endParam: string;
  userFilter: string = "";
  locationFilter: string = "";
  startFilter: string = "";
  endFilter: string = "";
  currParams: string;
  selectedId: number = 0;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {
    this.results = [];
    this.pageParam = parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.userParam = this.route.snapshot.queryParamMap.get('user') || "";
    this.locParam = this.route.snapshot.queryParamMap.get('loc') || "";
    this.startParam = this.route.snapshot.queryParamMap.get('start') || "";
    this.endParam = this.route.snapshot.queryParamMap.get('end') || "";
    this.userFilter = this.userParam;
    this.locationFilter = this.locParam;
    this.startFilter = this.startParam;
    this.endFilter = this.endParam;
    this.currParams = (this.userParam.length > 0 ? "&user=" + this.userParam : "") + (this.locParam.length > 0 ? "&loc=" + this.locParam : "")
    + (this.startParam.length > 0 ? "&start=" + this.startParam : "") + (this.endParam.length > 0 ? "&end=" + this.endParam : "");
    this.getPosResults();
  }

  ngOnInit(): void {
    document.body.classList.add('bg-list');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-list');
  }

  getPosResults() {
    this.loading = true;
    this.userService.getPositiveResults(this.pageParam.toString(), this.userParam, this.locParam, this.startParam, this.endParam).subscribe({
      next: (data) => {
        this.loading = false;
        this.results = data.data;
        this.allPages = data.pages;
        this.currPage = data.currPage;
      },
      error: (data) => {
          this.loading = false;
          console.log(data);
      }
  });
  }

  filterPosResults() {
    this.loading = true;
    this.userService.getPositiveResults("0", this.userFilter, this.locationFilter, this.startFilter, this.endFilter).subscribe({
      next: (data) => {
        const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {page: 1, user: this.userFilter, loc: this.locationFilter, start: this.startFilter, end: this.endFilter}, queryParamsHandling : null}).toString();
        this.location.go(url);
        this.userParam = this.userFilter;
        this.locParam = this.locationFilter;
        this.startParam = this.startFilter;
        this.endParam = this.endFilter;
        this.currParams = (this.userParam.length > 0 ? "&user=" + this.userParam : "") 
        + (this.locParam.length > 0 ? "&loc=" + this.locParam : "") + (this.startParam.length > 0 ? "&start=" + this.startParam : "")
        + (this.endParam.length > 0 ? "&end=" + this.endParam : "");
        this.pageParam = 1;
        this.loading = false;
        this.results = data.data;
        this.allPages = data.pages;
        this.currPage = data.currPage;
      },
      error: (data) => {
          this.loading = false;
          console.log(data);
      }
  });
  }

  openModal(){
    $('#myModal').modal('show');
  }

  hideModal(){
    $('#myModal').modal('hide');
  }

  setId(i: number)  {
    this.selectedId = i;
    this.openModal();
  }

}
