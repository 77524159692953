<p class="h4 mt-5">
  Filters
</p>
<div class="card mt-3 mb-5">
    <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <label>Level</label>
            <select class="form-select form-control" [(ngModel)]="levelFilter">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div class="col-12 col-md-6">
            <label>Username</label>
            <input type="text" class="form-control" id="filter2" name="usernameFilter" [(ngModel)]="usernameFilter">
          </div>
          <div class="w-100 my-3">
          </div>
          <div class="col-12 col-md-6">
            <label>Email</label>
            <input type="text" class="form-control" id="filter3" name="emailFilter" [(ngModel)]="emailFilter">
          </div>
          <div class="col-12 col-md-6">
            <label>Phone</label>
            <input type="text" class="form-control" id="filter4" name="phoneFilter" [(ngModel)]="phoneFilter">
          </div>
          <div class="w-100 mb-3"></div>
        </div>
        <button [disabled]="loading" class="btn lblue-btn bigger">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Filter
      </button>
    </div>
  </div>
<button [disabled]="loading" class="btn lblue-btn bigger" (click)="isNewUser = !isNewUser">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{isNewUser ? 'Hide':'Add new user'}}
</button>
<p class="h4 mt-5" *ngIf="isNewUser">
  Add new user
</p>
<div class="card mt-3 mb-5" *ngIf="isNewUser">
  <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Level</label>
          <select class="form-select form-control" [(ngModel)]="nUser.level">
            <option value="2">2</option>
            <option value="3" selected>3</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>Username</label>
          <input type="text" class="form-control" id="nUser2" name="usernameUser" [(ngModel)]="nUser.username">
        </div>
        <div class="w-100 my-3">
        </div>
        <div class="col-12 col-md-6">
          <label>Email</label>
          <input type="text" class="form-control" id="nUser3" name="emailUser" [(ngModel)]="nUser.email">
        </div>
        <div class="col-12 col-md-6">
          <label>Phone</label>
          <input type="text" class="form-control" id="nUser4" name="phoneUser" [(ngModel)]="nUser.phone">
        </div>
        <div class="w-100 my-3"></div>
        <div class="col-12 col-md-6">
          <label>First name</label>
          <input type="text" class="form-control" id="nUser5" name="lnameUser" [(ngModel)]="nUser.firstName">
        </div>
        <div class="col-12 col-md-6">
          <label>Last name</label>
          <input type="text" class="form-control" id="nUser6" name="fnameUser" [(ngModel)]="nUser.lastName">
        </div>
        <div class="w-100 mb-3"></div>
      </div>
      <button [disabled]="loading" class="btn lblue-btn bigger" (click)="addNewUser()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Add user
    </button>
  </div>
</div>
<p class="h4 mt-5">
  Manage users
</p>
<div *ngIf="loading" class="text-center mt-3">
    <div class="spinner-border text-lblue" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="!loading && usersList.length > 0"class="table-wrapper">
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Username</th>
            <th scope="col">Level</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Created At</th>
            <th scope="col">Manage</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of usersList; let i = index">
            <th scope="row">{{i+1}}.</th>
            <td>{{item.username}}</td>
            <td>
                <select class="form-select form-control" [(ngModel)]="currEditUser.level" *ngIf="currEdit === i">
                    <option value="1">Level 1</option>
                    <option value="2">Level 2</option>
                    <option value="3">Level 3</option>
                </select>
                <span *ngIf="currEdit !== i">
                    Level {{item.level}}
                </span>
            </td>
            <td>{{item.email}}</td>
            <td><input *ngIf="currEdit === i" type="text" class="form-control" id="example3" name="phone" [(ngModel)]="currEditUser.phone"><span *ngIf="currEdit !== i">{{item.phone}}</span></td>
            <td>{{item.createdAt}}</td>
            <td>
                <button class="btn lblue-btn" (click)="setEditor(i)">{{currEdit === i ? 'Close':'Edit'}}</button>
                <button class="btn lblue-btn mt-1" data-bs-toggle="modal" data-bs-target="#myModal" (click)="openModal()" *ngIf="currEdit === i">Save</button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<p class="text-center h3" *ngIf="!loading && usersList.length === 0">
    There are no users.
</p>
<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" *ngIf="currEdit != null">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title text-center" id="exampleModalLabel">Are you want to save changes?</h5>
          <div class="text-center my-4">
            <p *ngIf="usersList[currEdit].level != currEditUser.level">Level: Level {{usersList[currEdit].level}} -> Level {{currEditUser.level}}</p>
            <p *ngIf="usersList[currEdit].phone != currEditUser.phone">Phone: {{usersList[currEdit].phone}} -> {{currEditUser.phone}}</p>
          </div>
          <div class="text-center">
            <button type="button" class="btn lblue-btn bigger d-inline-block mr-3" (click)="submitChange()">Yes</button>
            <button type="button" class="btn lblue-btn bigger d-inline-block" data-bs-dismiss="modal" (click)="hideModal()">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
