import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            const currentUserLevel = this.authenticationService.currentUserLevel;
            if(currentUserLevel == -1) {
                this.router.navigate(['/set-test']); 
            } else {
                this.router.navigate(['/positive-results']);
            }
        }
    }

    ngOnInit() {
        document.body.classList.add('bg-login');
        this.loginForm = this.formBuilder.group({

            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngOnDestroy() {
        document.body.classList.remove('bg-login');
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .subscribe({
                next: (data) => {
                    if (!data.error) {
                        const currentUserLevel = this.authenticationService.currentUserLevel;
                        if (currentUserLevel == -1) {
                            const returnUrl = 'set-test';
                            this.router.navigate([returnUrl]);
                        } else {
                            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                            this.router.navigate([returnUrl]);
                        }
                    } else {
                        this.error = 'Invalid username or password!';
                        this.loading = false;
                    }
                },
                error: (data) => {
                    this.error = '';
                    if (data.errorCode === 1) {
                      this.error = 'Invalid METHOD!';
                    }
                    if (data.errorCode === 2) {
                      this.error = 'Invalid data in request!';
                    }
                    this.loading = false;
                }
            });
    }
}
