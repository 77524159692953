import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    private routerPaths = {
        'statistics': 2,
        'positive-results': 3,
        'all-results': 2,
        'manage-users': 1,
        'set-test': -1,
        'generate-barcode': 1,
        'login': null,
    }

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        const currentUserLevel = this.authenticationService.currentUserLevel;
        if (currentUser && currentUserLevel) {
            // logged in so return true
            if (currentUserLevel == -1 && route.routeConfig.path !== 'set-test') {
                this.router.navigate(['/set-test']);
            } 
            if (currentUserLevel <= this.routerPaths[route.routeConfig.path] || this.routerPaths[route.routeConfig.path] === null) {
                return true;
            }
            this.router.navigate(['/positive-results']);
            return false;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}