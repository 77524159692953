<p class="h4 mt-5">
  Filters
</p>
<div class="card mt-3 mb-5">
  <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>User ID</label>
          <input type="text" class="form-control" id="filter1" name="userFilter" [(ngModel)]="userFilter">
        </div>
        <div class="col-12 col-md-6">
          <label>Location</label>
          <input type="text" class="form-control" id="filter2" name="locationFilter" [(ngModel)]="locationFilter">
        </div>
        <div class="w-100 my-3">
        </div>
        <div class="col-12 col-md-6">
          <label>Date from</label>
          <input class="form-control" type="date" id="filter3" name="startFilter" [(ngModel)]="startFilter">
        </div>
        <div class="col-12 col-md-6">
          <label>Date To</label>
          <input class="form-control" type="date" id="filter4" name="endFilter" [(ngModel)]="endFilter">
        </div>
        <div class="w-100 mb-3"></div>
      </div>
      <button [disabled]="loading" class="btn lblue-btn bigger" (click)="filterPosResults()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Filter
      </button>
  </div>
</div>
<p class="h4">
  Positive results
</p>
<div *ngIf="loading" class="text-center mt-3">
    <div class="spinner-border text-lblue" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="!loading && results.length > 0" class="table-wrapper">
  <table  class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Test type</th>
          <th scope="col">Location</th>
          <th scope="col">User ID</th>
          <th scope="col">Test Date</th>
          <th scope="col">Patient details</th>
          <!--<th scope="col">Firstname</th>
          <th scope="col">Lastname</th>
          <th scope="col">Phone Number</th>-->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of results; let i = index">
          <th scope="row">{{i+1 + ((pageParam - 1) * 50)}}.</th>
          <td>{{item.testType}}</td>
          <td>{{item.location}}</td>
          <td>{{item.userId}}</td>
          <td>{{item.testDate}}</td>
          <td>
            <button class="btn lblue-btn" (click)="setId(i)">View</button>
          </td>
          <!--<td>{{item.firstName}}</td>
          <td>{{item.lastName}}</td>
          <td>{{item.phoneNum}}</td>-->
        </tr>
      </tbody>
  </table>
</div>
<div class="d-flex justify-content-center" *ngIf="results.length > 0 && allPages > 1">
  <nav>
    <ul class="pagination">
      <li class="page-item page-btn" *ngIf="pageParam > 1">
        <a class="page-link" href="?page=1{{currParams}}" aria-label="First Page">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item page-btn" *ngIf="pageParam > 1">
        <a class="page-link" href="?page={{pageParam - 1}}{{currParams}}" aria-label="Previous">
          <span aria-hidden="true">&lt;</span>
        </a>
      </li>
      <li class="page-item" *ngIf="pageParam > 1"><a class="page-link" href="?page={{pageParam - 1}}{{currParams}}">{{pageParam - 1}}</a></li>
      <li class="page-item active"><a class="page-link active" href="?page={{pageParam}}{{currParams}}">{{pageParam}}</a></li>
      <li class="page-item" *ngIf="pageParam < allPages"><a class="page-link" href="?page={{pageParam+1}}{{currParams}}">{{pageParam + 1}}</a></li>
      <li class="page-item" *ngIf="pageParam == 1 && allPages > pageParam && allPages > 2"><a class="page-link" href="?page={{pageParam+2}}{{currParams}}">{{pageParam + 2}}</a></li>
      <li class="page-item page-btn" *ngIf="pageParam < allPages">
        <a class="page-link" href="?page={{pageParam + 1}}{{currParams}}" aria-label="Next">
          <span aria-hidden="true">&gt;</span>
        </a>
      </li>
      <li class="page-item page-btn" *ngIf="pageParam < allPages">
        <a class="page-link" href="?page={{allPages}}{{currParams}}" aria-label="Last">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
<p class="text-center h3" *ngIf="!loading && results.length === 0">
  There are no records.
</p>
<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" *ngIf="selectedId !== null && results.length > 0">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h5 class="modal-title" id="exampleModalLabel"><b>Test details</b></h5>
        <p class="my-3"><b>Test ID:</b> {{results[selectedId].testId}}</p>
        <p class="my-3"><b>User ID:</b> {{results[selectedId].userId}}</p>
        <p class="my-3"><b>Location:</b> {{results[selectedId].location}}</p>
        <p class="my-3"><b>Test Date:</b> {{results[selectedId].testDate}}</p>
        <br/>
        <h5 class="modal-title" id="exampleModalLabel"><b>Patient details</b></h5>
        <div class="my-4">
          <div class="row">
            <div class="col-12">
              <p><b>Firstname:</b> {{results[selectedId].firstName}}</p>
            </div>
            <div class="col-12">
              <p><b>Lastname:</b> {{results[selectedId].lastName}}</p>
            </div>
            <div class="col-12">
              <p><b>Email:</b> {{results[selectedId].email}}</p>
            </div>
            <div class="col-12">
              <p><b>Phone:</b> {{results[selectedId].phoneNum}}</p>
            </div>
            <div class="col-12">
              <p><b>Date of birth:</b> {{results[selectedId].birthDate}}</p>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn lblue-btn bigger d-inline-block" data-bs-dismiss="modal" (click)="hideModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>