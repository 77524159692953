<p class="h4 mt-5">
  Filters
</p>
<div class="card mt-4">
  <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <label>Result</label>
          <select class="form-select form-control" [(ngModel)]="resultFilter">
            <option value="">All</option>
            <option value="0">Not finished</option>
            <option value="1">Negative</option>
            <option value="2">Positive</option>
            <option value="3">Invalid</option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>Test ID</label>
          <input type="text" class="form-control" id="filter2" name="testIdFilter" [(ngModel)]="testIdFilter">
        </div>
        <div class="w-100 my-3">
        </div>
        <div class="col-12 col-md-6">
          <label>Date from</label>
          <input class="form-control" type="datetime-local" id="filter3">
        </div>
        <div class="col-12 col-md-6">
          <label>Date To</label>
          <input class="form-control" type="datetime-local" id="filter4">
        </div>
        <div class="w-100 mb-3">
        </div>
      </div>
      <button [disabled]="loading" class="btn lblue-btn bigger">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Filter
      </button>
  </div>
</div>
<p class="h4 mt-5">
  All results
</p>
<div *ngIf="loading" class="text-center mt-3">
    <div class="spinner-border text-lblue" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="table-wrapper">
  <table *ngIf="!loading && results.length > 0" class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Device ID</th>
        <th scope="col">Test ID</th>
        <th scope="col">Patient ID</th>
        <th scope="col">Test type</th>
        <th scope="col">Result</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of results; let i = index">
        <th scope="row">{{i+1}}.</th>
        <td>{{item.deviceId}}</td>
        <td [ngClass]="item.testId.length > 20 ? 'long-split':''" title="{{item.testId}}">{{item.testId}}</td>
        <td [ngClass]="item.patientId.length > 20 ? 'long-split':''" title="{{item.patientId}}">{{item.patientId}}</td>
        <td>{{item.testType}}</td>
        <td>{{statuses[item.testResult]}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center" *ngIf="results.length > 0 && allPages > 1">
  <nav>
    <ul class="pagination">
      <li class="page-item page-btn" *ngIf="pageParam > 1">
        <a class="page-link" href="?page=1" aria-label="First Page">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item page-btn" *ngIf="pageParam > 1">
        <a class="page-link" href="?page={{pageParam - 1}}" aria-label="Previous">
          <span aria-hidden="true">&lt;</span>
        </a>
      </li>
      <li class="page-item" *ngIf="pageParam > 1"><a class="page-link" href="?page={{pageParam - 1}}">{{pageParam - 1}}</a></li>
      <li class="page-item active"><a class="page-link active" href="?page={{pageParam}}">{{pageParam}}</a></li>
      <li class="page-item" *ngIf="pageParam < allPages"><a class="page-link" href="?page={{pageParam+1}}">{{pageParam + 1}}</a></li>
      <li class="page-item" *ngIf="pageParam == 1 && allPages > pageParam"><a class="page-link" href="?page={{pageParam+2}}">{{pageParam + 2}}</a></li>
      <li class="page-item" *ngIf="pageParam < allPages">
        <a class="page-link page-btn" href="?page={{pageParam + 1}}" aria-label="Next">
          <span aria-hidden="true">&gt;</span>
        </a>
      </li>
      <li class="page-item page-btn" *ngIf="pageParam < allPages">
        <a class="page-link" href="?page={{allPages}}" aria-label="Last">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
<p class="text-center h3" *ngIf="!loading && results.length === 0">
  There are no records.
</p>