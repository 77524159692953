import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { PositiveResultsComponent } from './positive-results';
import { AllResultsComponent } from './all-results';
import { LoginComponent } from './login';
import { UserManageComponent } from './user-manage';
import { GenerateBarcodeComponent } from './generate-barcode';
import { SetTestComponent } from './set-test';
import { AuthGuard } from './_helpers';

const routes: Routes = [
    { path: 'statistics', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'positive-results', component: PositiveResultsComponent, canActivate: [AuthGuard] },
    { path: 'all-results', component: AllResultsComponent, canActivate: [AuthGuard] },
    { path: 'manage-users', component: UserManageComponent, canActivate: [AuthGuard] },
    { path: 'generate-barcode', component: GenerateBarcodeComponent, canActivate: [AuthGuard] },
    { path: 'set-test', component: SetTestComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: 'positive-results' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
