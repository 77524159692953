import { Component, OnInit } from '@angular/core';
import { AllResults } from '@app/_models';
import { UserService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-results',
  templateUrl: './all-results.component.html'
})
export class AllResultsComponent implements OnInit {

  results: AllResults[];
  loading: Boolean = true;
  statuses: String[] = ['Test not finished', 'Negative', 'Positive', 'Invalid'];
  allPages: number = 1;
  currPage: number = 1;
  pageParam: number;
  resultFilter: number;
  testIdFilter: string = "";

  constructor(private userService: UserService,private route: ActivatedRoute) {
    this.results = [];
    this.pageParam = parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.getAllResults();
  }

  ngOnInit(): void {
    document.body.classList.add('bg-list');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-list');
  }

  getAllResults() {
    this.loading = true;
    this.userService.getAllResults().subscribe({
      next: (data) => {
          this.loading = false;
          this.results = data;
      },
      error: (data) => {
          this.loading = false;
          console.log(data);
      }
  });
  }

}
