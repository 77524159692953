<p class="h4 mt-5">
    Generate Barcode
</p>
<div class="card mt-4">
    <div class="card-body">
        <button class="btn lblue-btn bigger text-center mr-auto ml-auto" (click)="generateBarcode()" *ngIf="genVal === null">Generate</button>
        <div *ngIf="genVal">
            <div class="text-center">
                <img class="img-fluid" [src]="'https://barcode.tec-it.com/barcode.ashx?data=' + genVal + '&code=Code128&multiplebarcodes=false&translate-esc=false&unit=Fit&dpi=96&imagetype=Gif&rotation=0&color=%23000000&bgcolor=%23ffffff&codepage=&qunit=Mm&quiet=0'" alt="Barcode">
                <p class="h4">
                    {{genVal}}
                </p>
            </div>
        </div>
        <button class="btn lblue-btn bigger text-center mt-2 mr-auto ml-auto" (click)="generateBarcode()" *ngIf="genVal !== null">Generate again</button>
    </div>
</div>

    